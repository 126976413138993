<template>
  <div>
    <div class="container mt-5 p-0 text-right">
      <div style="direction: rtl;">
        <header>
          <div class="row">
            <div class="col-8">
              <img
                src="../../../assets/logo.png"
                class="header-brand-img desktop-logo"
              />
            </div>
            <div class="col-4 float-left text-left mt-3 hidden-print">
              <button class="btn btn-info btn-sm show" @click="productDetail">
                مشاهده بدون جزییات
              </button>
            </div>
          </div>

          <table class="table mt-3 pb-4 border rounded">
            <thead class="thead-light">
              <tr>
                <th colspan="8">
                  <p class="text-center mb-0">مشخصات خریدار</p>
                </th>
              </tr>
            </thead>
            <tbody class="header-invoice">
              <tr>
                <td class="f-13"><b>نام : </b> {{ invoice.name }}</td>
                <td class="f-13"><b>موبایل : </b> {{ invoice.mobile }}</td>
                <td class="f-13">
                  <b>تاریخ سفارش : </b> {{ invoice.created_at | persianDate }}
                </td>
                <td class="f-13"><b>شناسه فاکتور : </b> {{ invoice.id }}</td>
              </tr>
              <tr>
                <td v-if="invoice.city" class="f-13" colspan="1">
                  <span v-if="invoice.city.province"
                    ><b>استان : </b> {{ invoice.city.province.name }} -
                    <b>شهر : </b> {{ invoice.city.name }}</span
                  >
                </td>
                <td class="f-13" colspan="3">
                  <span
                    ><b>آدرس : </b> {{ invoice.address }} - <b>کد پستی : </b>
                    {{ invoice.postal_code }} - <b>کد ملی : </b>
                    {{ invoice.national_code }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </header>
        <div class="main">
          <div v-if="productList" class=" d-block product-list">
            <table class="table mb-0 table-striped pb-4 border rounded">
              <thead>
                <tr>
                  <th scope="col">ردیف</th>
                  <th scope="col">نام محصول</th>
                  <th scope="col">قیمت محصول (تومان)</th>
                  <th scope="col">تعداد</th>
                  <th scope="col">تخفیف (درصد)</th>
                  <th scope="col">قیمت کل پس از تخفیف (تومان)</th>
                  <th scope="col">قیمت کل (تومان)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(order, index) in orders" :key="index">
                  <td scope="row">{{ index + 1 }}</td>
                  <td v-if="order.variety">{{ order.variety.fullTitle }}</td>
                  <td v-if="order.base_price">
                    {{ numberFormat(order.base_price) }}
                  </td>
                  <td>{{ order.quantity }}</td>
                  <td>{{ order.discount }}</td>
                  <td v-if="order.total">{{ numberFormat(order.total) }}</td>
                  <td v-if="order.amount">{{ numberFormat(order.amount) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row mx-0 border">
            <div class="col-7"></div>
            <div class="col-5 p-0 pr-4">
              <table class="table mb-0 " style="background-color:white;">
                <tbody>
                  <tr class="invoice-price">
                    <td class="f-13"><b> جمع کل (تومان) : </b></td>
                    <td v-if="invoice.SumTotalOrders">
                      {{ numberFormat(invoice.SumTotalOrders) }}
                    </td>
                    <td></td>
                  </tr>
                  <tr class="invoice-price">
                    <td class="f-13"><b>هزینه حمل (تومان) : </b></td>
                    <td>
                      <span
                        v-if="
                          invoice &&
                            invoice.shipping_method &&
                            invoice.shipping_method == 'tipax'
                        "
                      >
                        پرداخت درب منزل
                      </span>
                      <span v-else-if="invoice && invoice.delivery_amount">{{
                        numberFormat(invoice.delivery_amount)
                      }}</span>
                      <span v-else>رایگان</span>
                    </td>
                    <td></td>
                  </tr>

                  <tr class="invoice-price">
                    <td class="f-13"><b>شیوه ارسال : </b></td>
                    <td>
                      <span v-if="invoice.shipping_method == 'post'"
                        >پست معمولی</span
                      >
                      <span v-else-if="invoice.shipping_method == 'pishtaz'"
                        >پست پیشتاز</span
                      >
                      <span v-else-if="invoice.shipping_method == 'tipax'"
                        >پست تیپاکس</span
                      >
                    </td>
                    <td></td>
                  </tr>
                  <tr class="invoice-price">
                    <td class="f-13"><b>تخفیف (تومان) : </b></td>
                    <td>
                      <span v-if="invoice.total_discount"
                        >{{ numberFormat(invoice.total_discount) }}
                      </span>
                      <span v-else>ندارد</span>
                    </td>
                    <td></td>
                  </tr>
                  <tr class="invoice-price">
                    <td class="f-13"><b>مبلغ پرداختی (تومان) : </b></td>
                    <td v-if="invoice.AmountOrders">
                      {{ numberFormat(invoice.AmountOrders) }}
                    </td>
                    <td></td>
                  </tr>
                  <tr class="invoice-price">
                    <td class="f-13"><b>پرداخت از : </b></td>
                    <td v-if="invoice.transaction">
                      {{ invoice.transaction.gateway }}
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <footer>
            <div class="bg-light py-4 px-3 mt-3 border">
              <div class="text-center store-title mb-4">
                <h6><b>مشخصات فروشگاه</b></h6>
              </div>
              <div
                class="text-right store-address mt-3"
                v-if="setting.textarea"
              >
                <span><b> آدرس : </b></span>
                <span v-for="item in setting.textarea" :key="item.id">
                  <span v-if="item.key == 'address'">{{ item.value }}</span>
                </span>
              </div>
              <div class="text-right store-call mt-3" v-if="setting.text">
                <span><b> شماره تماس : </b></span>
                <span
                  class="store-phone-number"
                  v-for="item in setting.text"
                  :key="item.id"
                >
                  <span v-if="item.key == 'telephone'">{{ item.value }}</span>
                </span>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      invoice: {},
      orders: {},
      title: "چاپ فاکتور",
      setting: [],
      productList: true,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    numberFormat(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    productDetail() {
      if (this.productList) {
        this.productList = false;
      } else {
        this.productList = true;
      }
    },
  },
  mounted() {
    document.title = this.title;
    if (this.$route.params.id) {
      this.$axios
        .get(
          this.$root.baseUrl +
            "/api/admin/print/orders/" +
            this.$route.params.id
        )
        .then((response) => {
          this.invoice = response.data.data.invoice;
          this.orders = response.data.data.orders;
        });
      this.$axios
        .get(this.$root.baseUrl + "/api/admin/settings/general")
        .then((response) => {
          this.setting = response.data.data;
        });
    }
  },
  created() {},
  filters: {
    persianDate(date) {
      return window.moment(date).format("HH:mm jYYYY/jMM/jDD ");
    },
  },
};
</script>
<style>
.header-brand-img {
  height: 3.1rem;
}
.header-invoice td,
.main td {
  border-top: 0;
  font-size: 14px;
}
.main .product-list > .table th {
  font-size: 13px;
}
.list-group-item {
  border: 0;
}
.invoice-price {
  background-color: white !important;
}
.f-13 {
  font-size: 14px !important;
}
.store-address,
.store-call {
  font-size: 14px;
}
.store-address b,
.store-call b {
  font-size: 15px;
}
.store-call .store-phone-number span:after {
  content: " - ";
}
.store-call .store-phone-number span:last-child:after {
  content: "";
}
.store-title {
  font-weight: 400;
  color: #495057;
}
@media print {
  .hidden-print {
    display: none !important;
  }
}
</style>
